<template>
  <div class="menu">
    <the-header-menu :type="type" :active="active" />

    <template v-if="!$slots.default"> </template>
  </div>
</template>

<script>
import TheHeaderMenu from "./TheHeaderMenu.vue";

export default {
  components: {
    TheHeaderMenu,
  },
  props: {
    type: {
      type: String,
      default: "white",
    },
    active: {
      type: String,
      default: "首页",
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 1140px;
  margin: 0 auto;
}
</style>
