var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-[37px] mx-auto w-[1140px] flex justify-between"},[_c('a',{staticClass:"relative",attrs:{"href":"./home.html"}},[_c('img',{attrs:{"src":_vm.logSrc}}),_c('a',{staticClass:"absolute left-[-999px]"},[_vm._v("湖南数创信息技术有限公司")])]),_c('ul',{staticClass:"flex"},[_c('li',{class:[_vm.active === '首页' ? 'font-bold' : '', _vm.textColor]},[_c('a',{attrs:{"href":"./home.html"}},[_vm._v("首页")])]),_c('li',{class:[
        _vm.active === '产品中心' ? 'font-bold' : '',
        _vm.textColor,
        'ml-[75px]',
        'leading-[22px]',
      ]},[_c('a',{attrs:{"href":"./product_center.html"}},[_vm._v("产品中心")])]),_c('li',{class:[
        _vm.active === '解决方案' ? 'font-bold' : '',
        _vm.textColor,
        'ml-[75px]',
        'leading-[22px]',
      ]},[_c('a',{attrs:{"href":"./solution.html"}},[_vm._v("解决方案")])]),_c('li',{class:[
        _vm.active === '服务与支持' ? 'font-bold' : '',
        _vm.textColor,
        'ml-[75px]',
        'leading-[22px]',
        'link',
      ],staticStyle:{"position":"relative"}},[_c('a',{attrs:{"href":"./service_and_support.html"}},[_vm._v("服务与支持")]),_c('ul',{staticClass:"popup"},[_c('li',[_c('a',{on:{"click":function($event){return _vm.jump('/partners')}}},[_vm._v("合作伙伴")])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.jump('/contact_us')}}},[_vm._v("试用申请")])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.jump('/support')}}},[_vm._v("服务支持")])])])]),_c('li',{class:[
        _vm.active === '关于我们' ? 'font-bold' : '',
        _vm.textColor,
        'ml-[75px]',
        'leading-[22px]',
      ]},[_c('a',{attrs:{"href":"./about_us.html"}},[_vm._v("关于我们")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }