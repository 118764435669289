import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/support",
  },
  {
    path: "/partners",
    component: () => import("../views/partners.vue"),
  },
  {
    path: "/contact_us",
    component: () => import("../views/contactUs.vue"),
  },
  {
    path: "/download",
    component: () => import("../views/download.vue"),
  },
  {
    path: "/support",
    component: () => import("../views/support.vue"),
  },
];

const router = new Router({
  mode: "hash", // 使用 'hash' 模式
  routes,
});

export default router;
