<template>
  <!-- 菜单 -->
  <div class="pt-[37px] mx-auto w-[1140px] flex justify-between">
    <a class="relative" href="./home.html">
      <img :src="logSrc" />
      <a class="absolute left-[-999px]">湖南数创信息技术有限公司</a>
    </a>
    <ul class="flex">
      <li :class="[active === '首页' ? 'font-bold' : '', textColor]">
        <a href="./home.html">首页</a>
      </li>
      <li
        :class="[
          active === '产品中心' ? 'font-bold' : '',
          textColor,
          'ml-[75px]',
          'leading-[22px]',
        ]"
      >
        <a href="./product_center.html">产品中心</a>
      </li>
      <li
        :class="[
          active === '解决方案' ? 'font-bold' : '',
          textColor,
          'ml-[75px]',
          'leading-[22px]',
        ]"
      >
        <a href="./solution.html">解决方案</a>
      </li>
      <li
        :class="[
          active === '服务与支持' ? 'font-bold' : '',
          textColor,
          'ml-[75px]',
          'leading-[22px]',
          'link',
        ]"
        style="position: relative"
      >
        <a href="./service_and_support.html">服务与支持</a>
        <ul class="popup">
          <li>
            <a @click="jump('/partners')">合作伙伴</a>
          </li>
          <!-- <li><a href="./service_and_support.html#/download">资料下载</a></li> -->
          <li><a @click="jump('/contact_us')">试用申请</a></li>
          <li><a @click="jump('/support')">服务支持</a></li>
        </ul>
      </li>
      <li
        :class="[
          active === '关于我们' ? 'font-bold' : '',
          textColor,
          'ml-[75px]',
          'leading-[22px]',
        ]"
      >
        <a href="./about_us.html">关于我们</a>
      </li>
    </ul>
  </div>
</template>

<script>
import serverouter from "@/pages/service_and_support/router";

export default {
  props: {
    type: {
      type: String,
      default: "white",
    },
    active: {
      type: String,
      default: "首页",
    },
  },
  computed: {
    textColor: function () {
      switch (this.type) {
        case "white":
          return "text-white";
        case "black":
          return "text-black";
        default:
          return "text-white";
      }
    },
    logSrc: function () {
      switch (this.type) {
        case "white":
          return require("./../assets/logo.svg");
        case "black":
          return require("./../assets/logo2.svg");
        default:
          return require("./../assets/logo.svg");
      }
    },
  },
  methods: {
    jump(hash) {
      // console.log(window.location.pathname === '/service_and_support.html');
      if (window.location.pathname !== "/service_and_support.html") {
        window.location.href = "./service_and_support.html#" + hash;
      }
      if (hash === window.location.hash.substring(1)) return;
      serverouter.push({
        path: hash,
        force: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.link:hover .popup {
  height: 125px; // 160
}
.popup {
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 28px;
  width: 150px;
  background-color: #fff;
  color: black;
  border-radius: 4px;
  font-weight: normal;
  line-height: 40px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* 水平偏移量 | 垂直偏移量 | 模糊半径 | 颜色 */
  transition: height 0.5s ease; /* 添加过渡效果 */
  li:hover {
    color: #1565d8;
  }
}

.text-white {
  color: #fff;
}
.text-black {
  color: black;
}
</style>
